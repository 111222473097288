import React, { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import CalendarDaysIcon from "@heroicons/react/24/solid/CalendarDaysIcon";
import MagnifyingGlassIcon from "@heroicons/react/24/outline/MagnifyingGlassIcon";
import XMarkIcon from "@heroicons/react/24/solid/XMarkIcon";
import { RightDrawerLayout } from "../layouts/RightDrawerLayout";
import I18n from "../common/I18n";
import i18n from "../../utils/i18n";
import TransactionRecord from "./TransactionRecord";
import CashflowItem from "../cashflows/CashflowItem";
import RepeatingItem from "./RepeatingItem";
import api from "../../utils/api";
import routes from "../../utils/routes";
import ChevronDownIcon from "@heroicons/react/24/solid/ChevronDownIcon";
import ChevronRightIcon from "@heroicons/react/24/solid/ChevronRightIcon";

function ShowDrawerContent({
  uid,
  currency,
  fx_rates,
  current_user,
  selection,
  upcomingItems,
  openCashflowForm,
  openTransactionForm,
}) {
  const [openProjectedList, setOpenProjectedList] = useState(true);
  const [openFutureList, setOpenFutureList] = useState(false);
  const [futureItems, setFutureItems] = useState(undefined);
  const [searchText, setSearchText] = useState(
    uid?.length > 0 ? uid.map((u) => `#${u}`).join(" ") : "",
  );
  const [searchItems, setSearchItems] = useState(undefined);
  const [searchBar, setSearchBar] = useState(false);
  const toggleSearchBar = () => {
    if (searchBar) {
      setSearchItems(undefined);
      setSearchText("");
    }
    setSearchBar(!searchBar);
  };
  const onToggleProjectedList = () => setOpenProjectedList(!openProjectedList);
  const onToggleFutureList = () => {
    if (!openFutureList) retrieveFutureItems();
    setOpenFutureList(!openFutureList);
  };
  const retrieveFutureItems = () => {
    api({
      url: routes.transactions({
        future_items: true,
        currency,
        format: "json",
      }),
      method: "get",
      onSuccess: (data) => {
        const items = data.future_cashflow_items
          .filter((item) => item.bank_account.currency == currency)
          .concat(
            data.future_transactions.filter(
              (item) =>
                item.src_currency == currency || item.dst_currency == currency,
            ),
          )
          .sort((a, b) =>
            (a.date || a.planned_at) > (b.date || b.planned_at) ? 1 : -1,
          );
        const repetitions = [
          ...new Set(
            items
              .filter((item) => item.repetition_id)
              .map((item) => item.repetition_id),
          ),
        ];
        const repeatingItems = repetitions
          .map((repetition_id) => {
            const _items = items.filter(
              (__item) => __item.repetition_id == repetition_id,
            );
            if (_items.length > 1) {
              return {
                repeating: true,
                repetition_id,
                items: _items,
                date: _items[0].date || _items[0].planned_at,
              };
            } else return null;
          })
          .filter((item) => item);
        const _futureItems = items
          .map((item) => {
            if (item.repetition_id) {
              const repetition = repeatingItems.find(
                (repeatingItem) =>
                  repeatingItem.repetition_id == item.repetition_id,
              );
              if (!repetition) return item;
              else if (
                repetition.date == item.date ||
                repetition.date == item.planned_at
              ) {
                return repetition;
              } else return null;
            }
            return item;
          })
          .filter((item) => item);
        setFutureItems(_futureItems);
        setOpenFutureList(true);
      },
    });
  };
  const retrieveSearchItems = (e) => {
    e?.preventDefault();
    if (searchText == "") {
      toggleSearchBar();
      return;
    }
    api({
      url: routes.transactions({
        search_text: searchText,
        currency,
        format: "json",
      }),
      method: "get",
      onSuccess: (data) => {
        const items = data.search_cashflow_items
          .concat(data.search_transactions)
          .sort((a, b) =>
            (a.date || a.planned_at) > (b.date || b.planned_at) ? 1 : -1,
          );
        setSearchItems(items);
      },
    });
  };
  useEffect(() => {
    retrieveFutureItems();
    if (uid?.length > 0) {
      retrieveSearchItems();
      setSearchBar(true);
    }
  }, []);

  return (
    <Stack spacing={2}>
      <Card>
        <CardHeader
          title={
            (!searchBar && (
              <>
                <IconButton onClick={onToggleProjectedList}>
                  <SvgIcon>
                    {(openProjectedList && <ChevronDownIcon />) || (
                      <ChevronRightIcon />
                    )}
                  </SvgIcon>
                </IconButton>
                <I18n projected_cashflow_items />
              </>
            )) || (
              <form
                style={{ display: "flex", alignItems: "center" }}
                onSubmit={retrieveSearchItems}
              >
                <TextField
                  autoFocus
                  fullWidth
                  autoComplete="off"
                  label={i18n.t("search")}
                  variant="standard"
                  name="search_text"
                  value={searchText}
                  onChange={(event) => setSearchText(event.target.value)}
                />
                <IconButton type="submit">
                  <SvgIcon>
                    <MagnifyingGlassIcon />
                  </SvgIcon>
                </IconButton>
                <IconButton onClick={toggleSearchBar}>
                  <SvgIcon>
                    <XMarkIcon />
                  </SvgIcon>
                </IconButton>
              </form>
            )
          }
          action={
            !searchBar && (
              <IconButton onClick={toggleSearchBar}>
                <SvgIcon>
                  <MagnifyingGlassIcon />
                </SvgIcon>
              </IconButton>
            )
          }
          subheader={
            !searchBar &&
            !searchItems &&
            i18n.t("projected_transactions_subtitle")
          }
        />
        {!searchItems && openProjectedList && (
          <>
            <Divider />
            <CardContent>
              {upcomingItems.map(
                (item, i) =>
                  (item.date && (
                    <CashflowItem
                      key={i}
                      item={item}
                      fx_rates={fx_rates}
                      currency={currency}
                      onClick={() => openCashflowForm(item)}
                      selected={selection.uid == item.uid}
                    />
                  )) || (
                    <TransactionRecord
                      key={i}
                      transaction={item}
                      currency={currency}
                      readOnly={current_user.role_value == "read_only"}
                      fx_rates={fx_rates}
                      selected={selection.uid == item.uid}
                      onClick={
                        item.status == "placed"
                          ? () => openTransactionForm(item)
                          : undefined
                      }
                    />
                  ),
              )}
              {upcomingItems.length == 0 && <I18n no_scheduled_automation />}
            </CardContent>
          </>
        )}
        {searchItems && (
          <>
            <Divider />
            <CardContent>
              {searchItems.map(
                (item, i) =>
                  (item.date && (
                    <CashflowItem
                      key={i}
                      item={item}
                      fx_rates={fx_rates}
                      currency={currency}
                      onClick={() => openCashflowForm(item)}
                      selected={selection.uid == item.uid}
                    />
                  )) || (
                    <TransactionRecord
                      key={i}
                      transaction={item}
                      currency={currency}
                      readOnly={current_user.role_value == "read_only"}
                      fx_rates={fx_rates}
                      selected={selection.uid == item.uid}
                      onClick={
                        item.status == "placed"
                          ? () => openTransactionForm(item)
                          : undefined
                      }
                    />
                  ),
              )}
              {searchItems.length == 0 && <I18n no_scheduled_automation />}
            </CardContent>
          </>
        )}
      </Card>
      {!searchItems && (
        <Card>
          <CardHeader
            subheader={i18n.t("future_items_subtitle")}
            title={
              <>
                <IconButton onClick={onToggleFutureList}>
                  <SvgIcon>
                    {(openFutureList && <ChevronDownIcon />) || (
                      <ChevronRightIcon />
                    )}
                  </SvgIcon>
                </IconButton>
                <I18n future_items />
              </>
            }
          />
          {openFutureList && (
            <>
              <Divider />
              <CardContent>
                {futureItems &&
                  futureItems.map(
                    (item, i) =>
                      (item.repeating && (
                        <RepeatingItem
                          key={i}
                          item={item}
                          fx_rates={fx_rates}
                          currency={currency}
                          selection={selection}
                          readOnly={current_user.role_value == "read_only"}
                          openCashflowForm={openCashflowForm}
                          openTransactionForm={openTransactionForm}
                        />
                      )) ||
                      (item.date && (
                        <CashflowItem
                          key={i}
                          item={item}
                          fx_rates={fx_rates}
                          currency={currency}
                          selected={selection.uid == item.uid}
                          onClick={() => openCashflowForm(item)}
                        />
                      )) || (
                        <TransactionRecord
                          key={i}
                          transaction={item}
                          currency={currency}
                          readOnly={current_user.role_value == "read_only"}
                          fx_rates={fx_rates}
                          selected={selection.uid == item.uid}
                          onClick={
                            item.status == "placed"
                              ? () => openTransactionForm(item)
                              : undefined
                          }
                        />
                      ),
                  )}
                {futureItems && futureItems.length == 0 && (
                  <I18n no_scheduled_automation />
                )}
              </CardContent>
            </>
          )}
        </Card>
      )}
    </Stack>
  );
}

export default function (props) {
  const { open, onClose } = props;
  return (
    <RightDrawerLayout
      open={open}
      onClose={onClose}
      title={i18n.t("projected_transactions")}
      icon={CalendarDaysIcon}
    >
      <Typography variant="subtitle2" sx={{ mb: 2 }}>
        <I18n projected_transactions_subtitle />
        &nbsp;
        <I18n projected_transactions_subtitle_2 />
      </Typography>
      <ShowDrawerContent {...props} />
    </RightDrawerLayout>
  );
}
