import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import SvgIcon from "@mui/material/SvgIcon";
import IconButton from "@mui/material/IconButton";
import ChevronDoubleRightIcon from "@heroicons/react/24/solid/ChevronRightIcon";
import ChevronDoubleDownIcon from "@heroicons/react/24/solid/ChevronDownIcon";
import TransactionRecord from "./TransactionRecord";
import CashflowItem from "../cashflows/CashflowItem";

export default function ({
  item,
  fx_rates,
  currency,
  readOnly,
  selection,
  openCashflowForm,
  openTransactionForm,
}) {
  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen(!open);

  return (
    <Grid
      container
      spacing={0}
      sx={{
        alignItems: "start",
        borderRadius: 0,
        "&:not(:last-child)": { borderBottom: "solid 0.5px #eee" },
      }}
    >
      <Grid item sx={{ pt: 2, maxWidth: "40px!important" }}>
        <IconButton onClick={toggleOpen}>
          {open ? (
            <SvgIcon>
              <ChevronDoubleDownIcon />
            </SvgIcon>
          ) : (
            <SvgIcon>
              <ChevronDoubleRightIcon />
            </SvgIcon>
          )}
        </IconButton>
      </Grid>
      <Grid
        item
        sx={{
          maxWidth: "calc(100% - 40px)!important",
          flexBasis: "calc(100% - 40px)!important",
        }}
      >
        <Grid container>
          {item.items
            .slice(0, open ? undefined : 1)
            .map(
              (_item, i) =>
                (_item.date && (
                  <CashflowItem
                    key={i}
                    item={_item}
                    fx_rates={fx_rates}
                    currency={currency}
                    selected={selection.uid == _item.uid}
                    onClick={() => openCashflowForm(_item)}
                  />
                )) || (
                  <TransactionRecord
                    key={i}
                    transaction={_item}
                    currency={currency}
                    selected={selection.uid == _item.uid}
                    readOnly={readOnly}
                    fx_rates={fx_rates}
                    onClick={
                      _item.status == "placed"
                        ? () => openTransactionForm(_item)
                        : undefined
                    }
                  />
                ),
            )}
        </Grid>
      </Grid>
    </Grid>
  );
}
