import React, { useState, useEffect } from "react";
import BuildingOffice2Icon from "@heroicons/react/24/solid/BuildingOffice2Icon";
import { RightDrawerLayout } from "../../layouts/RightDrawerLayout";
import routes from "../../../utils/routes";
import api from "../../../utils/api";
import IconButton from "@mui/material/IconButton";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";
import DocumentTextIcon from "@heroicons/react/24/outline/DocumentTextIcon";
import ArrowRightStartOnRectangleIcon from "@heroicons/react/24/solid/ArrowRightOnRectangleIcon";
import I18n from "../../common/I18n";

const SYFE_LOGIN_URL = "https://www.syfe.com/login";

function ShowDrawerContent({ account }) {
  const [response, setResponse] = useState(undefined);
  useEffect(() => {
    api({
      method: "get",
      url: routes.console_account({ id: account.id }),
      onSuccess: (_response) => {
        setResponse(_response);
      },
    });
  }, []);

  return (
    <Stack spacing={2}>
      {(account.profile.asana_task_gid &&
        account.profile.onboarding_status !== "done" && (
          <Typography variant="body2">
            Onboarding tracker: &nbsp;
            <a
              target="_blank"
              href={routes.onboarding({
                id: account.profile.asana_task_gid,
              })}
              rel="noreferrer"
            >
              {routes.onboarding({
                id: account.profile.asana_task_gid,
                with_host: true,
              })}
            </a>
          </Typography>
        )) || (
        <Button
          sx={{ marginLeft: "auto" }}
          href={routes.toggle_onboarding_status_console_account({
            id: account.id,
          })}
        >
          Revert Onboarding Status
        </Button>
      )}
      {response && response.app_integrations?.length > 0 && (
        <Button
          sx={{ marginLeft: "auto!important" }}
          href={routes.generate_earning_report_console_account({
            id: account.id,
          })}
        >
          Generate Earning Analysis
        </Button>
      )}
      <Card>
        <CardHeader title="Users" />
        <Divider />
        <CardContent>
          {response?.users.map((user) => (
            <Grid key={user.id} container>
              <Grid item xs={2} md={1} sx={{ py: 1 }}>
                <Avatar
                  src={user.image}
                  variant="circle"
                  sx={{ height: 32, width: 32 }}
                />
              </Grid>
              <Grid
                item
                xs={10}
                md={4}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Typography variant="body1">
                  <b>{user.name}</b>
                </Typography>
              </Grid>
              <Grid
                item
                xs={10}
                md={5}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Typography variant="body2">{user.email}</Typography>
              </Grid>
              <Grid
                item
                xs={2}
                md={2}
                sx={{ display: "flex", alignItems: "center" }}
              >
                {(user.name === "CashWise Support" && (
                  <Button
                    target="_blank"
                    href={routes.new_user_session({
                      email: user.email,
                      password: user.profile.login_password,
                    })}
                    variant="contained"
                  >
                    Login
                  </Button>
                )) || <Typography variant="body2">{user.role}</Typography>}
              </Grid>
            </Grid>
          ))}
        </CardContent>
      </Card>
      <Card>
        <CardHeader title="Bank Accounts" />
        <Divider />
        <Grid container sx={{ my: 2 }}>
          {response && response.bank_account_balances?.length == 0 && (
            <Grid item xs={12} sx={{ p: 4 }}>
              <Typography variant="body1">No bank accounts</Typography>
            </Grid>
          )}
          {response?.bank_account_balances?.map((balance, i) => (
            <Grid
              key={i}
              item
              xs={12}
              md={6}
              sx={{
                px: 3,
                py: 1,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <Avatar
                  src={balance.bank_account.bank.image}
                  variant="rounded"
                  sx={{ mr: 1, width: 24, height: 24 }}
                />
                <Typography variant="body2">
                  {balance.bank_account.name}
                  {balance.bank_account.status == "suspended" && " (suspended)"}
                </Typography>
                &nbsp;&nbsp;&nbsp;
                {balance.bank_account.bank_type == "investment" && (
                  <IconButton
                    color="primary"
                    target="_blank"
                    href={
                      SYFE_LOGIN_URL +
                      "?account_id=" +
                      balance.bank_account.account_id
                    }
                  >
                    <SvgIcon>
                      <ArrowRightStartOnRectangleIcon />
                    </SvgIcon>
                  </IconButton>
                )}
                {balance.bank_account.pdf_confirmation && (
                  <IconButton
                    target="_blank"
                    href={routes.confirmation_letter({
                      id: balance.bank_account.pdf_confirmation,
                      format: "pdf",
                    })}
                  >
                    <SvgIcon>
                      <DocumentTextIcon />
                    </SvgIcon>
                  </IconButton>
                )}
                {balance.bank_account.bank.profile.capabilities?.includes(
                  "get_account_proof_letter",
                ) && (
                  <IconButton
                    target="_blank"
                    href={routes.get_account_proof_letter_console_account({
                      id: balance.bank_account.id,
                      format: "pdf",
                    })}
                  >
                    <SvgIcon>
                      <DocumentTextIcon />
                    </SvgIcon>
                  </IconButton>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "end",
                }}
              >
                <Typography variant="body2">
                  <b>
                    <I18n
                      l="currency"
                      v={balance.account_balance?.amount || 0}
                      currency={balance.bank_account.currency}
                    />
                  </b>
                </Typography>
                <Typography variant="subtitle2">
                  {balance.account_balance && (
                    <>
                      Updated:&nbsp;
                      <I18n
                        l="date.short"
                        v={balance.account_balance?.updated_at}
                      />
                    </>
                  )}
                </Typography>
              </div>
              {balance.bank_account.bank_type == "transactional" && (
                <div style={{ width: "100%", paddingLeft: 32 }}>
                  <Typography variant="body2">
                    Minimum Target Balance:&nbsp;
                    <b>
                      <I18n
                        l="number"
                        v={balance.bank_account.profile.min_balance}
                        delimiter=","
                        precision={2}
                      />
                    </b>
                    <br />
                    Minimum Transfer Amount:&nbsp;
                    <b>
                      <I18n
                        l="number"
                        v={balance.bank_account.profile.min_transfer}
                        delimiter=","
                        precision={2}
                      />
                    </b>
                    <br />
                    Threshold Balance:&nbsp;
                    <b>
                      <I18n
                        l="number"
                        v={balance.bank_account.profile.threshold_balance}
                        delimiter=","
                        precision={2}
                      />
                    </b>
                  </Typography>
                </div>
              )}
            </Grid>
          ))}
        </Grid>
      </Card>
    </Stack>
  );
}

export default function (props) {
  const { open, onClose, account } = props;
  return (
    <RightDrawerLayout
      open={open}
      onClose={onClose}
      title={account.name}
      icon={BuildingOffice2Icon}
    >
      <ShowDrawerContent {...props} onClose={onClose} />
    </RightDrawerLayout>
  );
}
