import React, { useState } from "react";
import { ConsoleApplicationLayout } from "../../layouts/ConsoleApplicationLayout";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import SvgIcon from "@mui/material/SvgIcon";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import MagnifyingGlassIcon from "@heroicons/react/24/outline/MagnifyingGlassIcon";
import XMarkIcon from "@heroicons/react/24/outline/XMarkIcon";
import ChevronRightIcon from "@heroicons/react/24/outline/ChevronRightIcon";
import ChevronDownIcon from "@heroicons/react/24/outline/ChevronDownIcon";
import Dropdown from "../../common/Dropdown";
import SubmitButton from "../../common/SubmitButton";
import TransactionRecord from "../../transactions/TransactionRecord";
import CashflowItem from "../../cashflows/CashflowItem";
import ShowDrawer from "./ShowDrawer";
import routes from "../../../utils/routes";
import api from "../../../utils/api";

function FilterPanel({
  open,
  accounts,
  search_account,
  search_text,
  onClose,
  retrieveSearch,
}) {
  const [searchAccountId, setSearchAccountId] = useState(
    search_account?.id || "",
  );
  const [searchText, setSearchText] = useState(search_text);
  const submit = (e) => {
    e.preventDefault();
    onClose();
    retrieveSearch(searchAccountId, searchText);
  };
  const reset = () => {
    setSearchAccountId("");
    setSearchText("");
  };

  return (
    <Modal open={open} onClose={onClose} disablePortal>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          boxShadow: 24,
        }}
      >
        <Card sx={{ p: 2 }}>
          <CardHeader
            title="Filter"
            action={
              <IconButton onClick={onClose}>
                <SvgIcon>
                  <XMarkIcon />
                </SvgIcon>
              </IconButton>
            }
          />
          <Divider />
          <CardContent>
            <form onSubmit={submit}>
              <Stack spacing={2}>
                <Dropdown
                  fullWidth
                  label="Account"
                  value={searchAccountId}
                  values={[{ value: "", name: "---" }].concat(
                    accounts.map((account) => ({
                      value: account.id,
                      name: `${account.id}. ${account.name}`,
                    })),
                  )}
                  onChange={(event) => setSearchAccountId(event.target.value)}
                  name="search_account_id"
                />
                <TextField
                  fullWidth
                  autoFocus
                  autoComplete="off"
                  label="Search"
                  variant="standard"
                  name="search_text"
                  onChange={(event) => setSearchText(event.target.value)}
                  value={searchText}
                />
                <SubmitButton type="submit" variant="contained" fullWidth>
                  Apply
                </SubmitButton>
                <Button fullWidth onClick={reset}>
                  Clear
                </Button>
              </Stack>
            </form>
          </CardContent>
        </Card>
      </Box>
    </Modal>
  );
}

export default function ({ accounts, current_user, fx_rates, ...props }) {
  const [openUpcoming, setOpenUpcoming] = useState(true);
  const [openPast, setOpenPast] = useState(false);
  const [search_account, setSearchAccount] = useState();
  const [search_text, setSearchText] = useState("");
  const [upcoming_cashflow_items, setUpcomingCashflowItems] = useState(
    props.upcoming_cashflow_items,
  );
  const [past_cashflow_items, setPastCashflowItems] = useState(
    props.past_cashflow_items,
  );
  const [upcoming_transactions, setUpcomingTransactions] = useState(
    props.upcoming_transactions,
  );
  const [planned_transactions, setPlannedTransactions] = useState(
    props.planned_transactions,
  );
  const [past_transactions, setPastTransactions] = useState(
    props.past_transactions,
  );
  const [showHistory, setShowHistory] = useState(undefined);
  const onCloseHistory = () => setShowHistory(undefined);
  const upcoming_items = upcoming_cashflow_items
    .concat(upcoming_transactions)
    .concat(planned_transactions)
    .sort((a, b) =>
      (a.date || a.planned_at) > (b.date || b.planned_at) ? 1 : -1,
    );
  const past_items = past_cashflow_items
    .concat(past_transactions)
    .sort((a, b) =>
      (a.date || a.planned_at) > (b.date || b.planned_at) ? -1 : 1,
    );
  const [filterPanelOpen, setFilterPanelOpen] = useState(false);
  const toggleFilterPanel = () => setFilterPanelOpen(!filterPanelOpen);
  const retrieveSearch = (search_account_id, search_text) => {
    api({
      method: "get",
      url: routes.console_automations({
        search_account_id,
        search_text: search_text || "",
        format: "json",
      }),
      onSuccess: (data) => {
        setOpenPast(true);
        setSearchAccount(data.search_account);
        setSearchText(data.search_text || "");
        setUpcomingCashflowItems(data.upcoming_cashflow_items);
        setPastCashflowItems(data.past_cashflow_items);
        setUpcomingTransactions(data.upcoming_transactions);
        setPlannedTransactions(data.planned_transactions);
        setPastTransactions(data.past_transactions);
      },
    });
  };
  const retrieveHistory = (item) => {
    api({
      method: "get",
      url: routes.retrieve_audits_console_automations({
        id: item.uid,
        format: "json",
      }),
      onSuccess: (data) => {
        console.log(item, data);
        setShowHistory({ item, history: data });
      },
    });
  };

  let filterText = "";
  if (search_account)
    filterText = `${search_account.id}. ${search_account.name}`;
  if (search_text) filterText += (filterText ? " - " : "") + `'${search_text}'`;
  if (filterText) filterText = ` (${filterText})`;

  return (
    <ConsoleApplicationLayout current_user={current_user}>
      <h1>Automation</h1>
      <Stack spacing={2}>
        <Card>
          <CardHeader
            title={
              <>
                <IconButton onClick={() => setOpenUpcoming(!openUpcoming)}>
                  <SvgIcon>
                    {openUpcoming ? <ChevronDownIcon /> : <ChevronRightIcon />}
                  </SvgIcon>
                </IconButton>
                {"Upcoming" + filterText}
              </>
            }
            action={
              <IconButton onClick={toggleFilterPanel}>
                <SvgIcon>
                  <MagnifyingGlassIcon />
                </SvgIcon>
              </IconButton>
            }
          />
          {openUpcoming && <Divider />}
          {openUpcoming && (
            <CardContent>
              <Stack spacing={2}>
                {upcoming_items.map(
                  (item, i) =>
                    (item.date && (
                      <CashflowItem
                        key={i}
                        fx_rates={fx_rates}
                        item={item}
                        currency={item.bank_account.currency}
                        onClick={() => retrieveHistory(item)}
                        console
                      />
                    )) || (
                      <TransactionRecord
                        console
                        key={i}
                        transaction={item}
                        currency={item.src_currency}
                        onClick={() => retrieveHistory(item)}
                        fx_rates={fx_rates}
                      />
                    ),
                )}
              </Stack>
            </CardContent>
          )}
        </Card>
        <Card>
          <CardHeader
            title={
              <>
                <IconButton onClick={() => setOpenPast(!openPast)}>
                  <SvgIcon>
                    {openPast ? <ChevronDownIcon /> : <ChevronRightIcon />}
                  </SvgIcon>
                </IconButton>
                {"Past" + filterText}
              </>
            }
          />
          {openPast && <Divider />}
          {openPast && (
            <CardContent>
              <Stack spacing={2}>
                {past_items.map(
                  (item, i) =>
                    (item.date && (
                      <CashflowItem
                        key={i}
                        fx_rates={fx_rates}
                        item={item}
                        currency={item.bank_account.currency}
                        onClick={() => retrieveHistory(item)}
                        console
                      />
                    )) || (
                      <TransactionRecord
                        console
                        key={i}
                        transaction={item}
                        currency={item.src_currency}
                        onClick={() => retrieveHistory(item)}
                        fx_rates={fx_rates}
                      />
                    ),
                )}
              </Stack>
            </CardContent>
          )}
        </Card>
      </Stack>
      <FilterPanel
        open={filterPanelOpen}
        accounts={accounts}
        retrieveSearch={retrieveSearch}
        onClose={toggleFilterPanel}
      />
      <ShowDrawer
        open={!!showHistory}
        onClose={onCloseHistory}
        item={showHistory?.item}
        audits={showHistory?.history}
        fx_rates={fx_rates}
      />
    </ConsoleApplicationLayout>
  );
}
