import React, { useState } from "react";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import SvgIcon from "@mui/material/SvgIcon";
import IconButton from "@mui/material/IconButton";
import { Fade } from "react-awesome-reveal";
import ChevronRightIcon from "@heroicons/react/24/solid/ChevronRightIcon";
import ChevronDownIcon from "@heroicons/react/24/solid/ChevronDownIcon";
import DocumentArrowDownIcon from "@heroicons/react/24/solid/DocumentArrowDownIcon";
import MagnifyingGlassIcon from "@heroicons/react/24/outline/MagnifyingGlassIcon";
import { Chart } from "react-google-charts";
import { ApplicationLayout } from "../layouts/ApplicationLayout";
import RefreshButton from "../common/RefreshButton";
import ShowDrawer from "./ShowDrawer";
import SearchDrawer from "./SearchDrawer";
import CashflowFormDrawer from "../cashflows/FormDrawer";
import I18n from "../common/I18n";
import i18n from "../../utils/i18n";
import routes from "../../utils/routes";
import { Tooltip } from "@mui/material";
import currencyName from "../../utils/currency-name";

const CURRENCIES = i18n.t("currencies");
const CHART_OPTIONS = {
  isStacked: true,
  legend: { position: "top", alignment: "center" },
  intervals: { style: "none" },
  bar: { groupWidth: "90%" },
  hAxis: {
    minorGridlines: { color: "transparent" },
    gridlines: { color: "transparent" },
  },
  vAxis: {
    minValue: 0,
    minorGridlines: { color: "transparent" },
    format: "decimal",
  },
  chartArea: { left: 80, top: 10, bottom: 20, width: "100%", height: "100%" },
  focusTarget: "category",
};
const COLORS =
  //['#AE9DFF', '#8476D3', '#6366F1', '#5B51A9', '#4338CA', '#312E81']
  ["#312E81", "#0054A3", "#0076B5", "#0095BA", "#00B2B7", "#5CCEB2"];
// see: https://mycolor.space/?hex=%23312E81&sub=1

function Statements({ statements }) {
  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen(!open);
  if (statements.length == 0) return null;

  return (
    <>
      <Typography
        variant="body1"
        sx={{ fontWeight: "bold", mt: 2, mb: open ? 1 : 0 }}
      >
        <IconButton onClick={toggleOpen}>
          {!open && (
            <SvgIcon>
              <ChevronRightIcon />
            </SvgIcon>
          )}
          {open && (
            <SvgIcon>
              <ChevronDownIcon />
            </SvgIcon>
          )}
        </IconButton>
        <I18n statements />
      </Typography>
      <Fade>
        {(open &&
          statements.map((statement) => (
            <Card
              key={statement.id}
              component="a"
              target="_blank"
              href={routes.account_trend({
                id: statement.identifier,
                format: "pdf",
              })}
              sx={{ p: 2, display: "flex", justifyContent: "space-between" }}
            >
              <SvgIcon>
                <DocumentArrowDownIcon />
              </SvgIcon>
              <Typography
                variant="body1"
                sx={{ ml: 2, mr: "auto", fontWeight: "bold" }}
              >
                <I18n
                  statement_month
                  year={new Date(statement.date).getFullYear()}
                  month={new Date(statement.date).toLocaleString("default", {
                    month: "long",
                  })}
                />
              </Typography>
              <Typography variant="body2">
                <I18n l="date.long" v={statement.date} />
              </Typography>
            </Card>
          ))) ||
          null}
      </Fade>
    </>
  );
}

function BankAccountBalance({
  bank_account,
  currency,
  balance,
  last_updated_at,
  past_cashflow_items,
  past_transactions,
  fx_rates,
  current_user,
  index,
}) {
  const [loading, setLoading] = useState(false);
  const [historyOpen, setHistoryOpen] = useState(false);
  const [cashflowFormOpen, setCashflowFormOpen] = useState(false);
  const openHistory = () => setHistoryOpen(true);
  const closeHistory = () => setHistoryOpen(false);
  const openCashflowForm = (cashflow_item) =>
    setCashflowFormOpen(cashflow_item || true);
  const closeCashflowForm = () => setCashflowFormOpen(false);
  const data = [["", ""]].concat(
    balance.map((record) => [
      {
        v: new Date(record.date),
        f: i18n.toTime("date.formats.short", new Date(record.date)),
      },
      {
        v: parseFloat(record.balance),
        f: i18n.numberToRounded(parseFloat(record.balance), {
          delimiter: ",",
          precision: 2,
        }),
      },
    ]),
  );
  const pastItems = past_cashflow_items
    .concat(past_transactions)
    .sort((a, b) =>
      (a.date || a.planned_at) > (b.date || b.planned_at) ? -1 : 1,
    );

  return (
    <Card>
      <CardHeader
        avatar={
          <Avatar
            src={bank_account.image}
            variant="rounded"
            sx={{ height: 32, width: 32 }}
          />
        }
        title={bank_account.name}
        action={
          <>
            <RefreshButton
              onClick={() => setLoading(true)}
              loading={loading}
              title={i18n.t("update")}
              href={routes.update_balance_account_trends()}
            />
            <Tooltip title={i18n.t("past_transactions")}>
              <IconButton onClick={openHistory}>
                <SvgIcon>
                  <ChevronRightIcon />
                </SvgIcon>
              </IconButton>
            </Tooltip>
          </>
        }
      />
      <Divider />
      <CardContent>
        <div
          onClick={openHistory}
          style={{ position: "relative", cursor: "pointer" }}
        >
          {data.length > 1 && (
            <Chart
              width="100%"
              height="250px"
              chartType="AreaChart"
              data={data}
              options={{
                ...CHART_OPTIONS,
                colors: [COLORS[index % COLORS.length]],
              }}
            />
          )}
          {data.length <= 1 && <I18n no_data />}
          {last_updated_at && (
            <Typography
              variant="caption"
              sx={{ position: "absolute", right: 16, top: -10 }}
            >
              <I18n last_updated />
              &nbsp;
              <I18n l="time.short" v={last_updated_at} />
            </Typography>
          )}
        </div>
        <Statements statements={bank_account.pdf_statements} />
      </CardContent>

      <ShowDrawer
        open={historyOpen}
        onClose={closeHistory}
        fx_rates={fx_rates}
        currency={currency}
        current_user={current_user}
        bank_account={bank_account}
        pastItems={pastItems}
        openCashflowForm={openCashflowForm}
      />
      <CashflowFormDrawer
        open={!!cashflowFormOpen}
        onClose={closeCashflowForm}
        readOnly={true}
        item={cashflowFormOpen === true ? null : cashflowFormOpen}
        bank_accounts={[bank_account]}
        defaultCurrency={currency}
        defaultDay={new Date()}
        current_user={current_user}
      />
    </Card>
  );
}

export default function ({
  bank_accounts,
  balances,
  fx_rates,
  past_cashflow_items,
  past_transactions,
  ...props
}) {
  const [cashflowFormOpen, setCashflowFormOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const openCashflowForm = (cashflow_item) =>
    setCashflowFormOpen(cashflow_item || true);
  const closeCashflowForm = () => setCashflowFormOpen(false);
  const openSearch = () => setSearchOpen(true);
  const closeSearch = () => setSearchOpen(false);
  const currencies = CURRENCIES.filter((currency) =>
    [...new Set(bank_accounts.map((item) => item.currency))].includes(
      currency.value,
    ),
  ).map((currency) => currency.value);
  const [currencyTab, setCurrencyTab] = useState(currencies[0]);
  const onChangeTab = (_, value) => setCurrencyTab(value);
  const pastItems = past_cashflow_items
    .filter((item) => item.bank_account.currency == currencyTab)
    .concat(
      past_transactions.filter(
        (item) =>
          item.src_bank_account.currency == currencyTab ||
          item.dst_bank_account.currency == currencyTab,
      ),
    )
    .sort((a, b) =>
      (a.date || a.planned_at) > (b.date || b.planned_at) ? -1 : 1,
    );

  return (
    <ApplicationLayout {...props}>
      <Stack spacing={2}>
        <Typography variant="h2">
          <I18n account_trends />
        </Typography>
        <div style={{ position: "relative" }}>
          <Tabs value={currencyTab} onChange={onChangeTab}>
            {currencies.map((currency) => (
              <Tab
                key={currency}
                value={currency}
                label={currencyName(currency)}
                sx={{ px: 1 }}
              />
            ))}
          </Tabs>
          <IconButton
            onClick={openSearch}
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <SvgIcon>
              <MagnifyingGlassIcon />
            </SvgIcon>
          </IconButton>
        </div>
        {currencies.length > 0 && <Divider sx={{ marginTop: "0!important" }} />}
        {balances
          .filter((balance) => balance.currency == currencyTab)
          .map((balance, i) => (
            <BankAccountBalance
              key={i}
              index={i}
              bank_account={balance.bank_account}
              balance={balance.balance}
              currency={balance.currency}
              last_updated_at={balance.last_updated_at}
              past_cashflow_items={past_cashflow_items.filter(
                (item) => item.bank_account.id == balance.bank_account.id,
              )}
              past_transactions={past_transactions.filter(
                (item) =>
                  item.src_bank_account.id == balance.bank_account.id ||
                  item.dst_bank_account.id == balance.bank_account.id,
              )}
              fx_rates={fx_rates}
              current_user={props.current_user}
            />
          ))}
      </Stack>
      <SearchDrawer
        open={searchOpen}
        onClose={closeSearch}
        fx_rates={fx_rates}
        currency={currencyTab}
        current_user={props.current_user}
        pastItems={pastItems}
        openCashflowForm={openCashflowForm}
      />
      <CashflowFormDrawer
        open={!!cashflowFormOpen}
        onClose={closeCashflowForm}
        readOnly={true}
        item={cashflowFormOpen === true ? null : cashflowFormOpen}
        bank_accounts={bank_accounts}
        defaultCurrency={currencyTab}
        defaultDay={new Date()}
        current_user={props.current_user}
      />
    </ApplicationLayout>
  );
}
