import React from "react";
import BuildingOffice2Icon from "@heroicons/react/24/solid/BuildingOffice2Icon";
import { RightDrawerLayout } from "../../layouts/RightDrawerLayout";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from "@mui/lab/TimelineOppositeContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TransactionRecord from "../../transactions/TransactionRecord";
import CashflowItem from "../../cashflows/CashflowItem";
import I18n from "../../common/I18n";
import i18n from "../../../utils/i18n";

function ShowDrawerContent({ item, audits, fx_rates }) {
  console.log(item);
  console.log(audits);
  return (
    <Stack spacing={2}>
      <Card>
        {(item.date && (
          <CashflowItem console item={item} fx_rates={fx_rates} />
        )) || (
          <TransactionRecord
            console
            transaction={item}
            history={history}
            fx_rates={fx_rates}
          />
        )}
      </Card>
      <Card>
        <CardHeader title="History" />
        <Divider />
        <CardContent>
          {audits.length > 0 && (
            <Timeline
              sx={{
                [`& .${timelineOppositeContentClasses.root}`]: {
                  flex: 0.2,
                },
              }}
            >
              {audits.map((audit, i) => (
                <TimelineItem key={i}>
                  <TimelineOppositeContent color="textSecondary">
                    <Typography variant="subtitle2" sx={{ fontSize: "75%" }}>
                      {i18n.toTime(
                        "time.formats.short",
                        Date.parse(audit.created_at),
                      )}
                    </Typography>
                    {audit.user && (
                      <Typography variant="subtitle2" sx={{ fontSize: "75%" }}>
                        {audit.user.name}
                      </Typography>
                    )}
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot />
                    {audits.length - 1 > i && <TimelineConnector />}
                  </TimelineSeparator>
                  <TimelineContent>
                    <Typography variant="body2" sx={{ fontSize: "75%" }}>
                      {audit.action == "create" && !item.app_integration && (
                        <I18n audit_create />
                      )}
                      {audit.action == "create" && item.app_integration && (
                        <I18n
                          audit_create_with_app
                          app={item.app_integration.app.name}
                        />
                      )}
                      {audit.action == "update" &&
                        audit.changes.map((change, j) => (
                          <>
                            <I18n
                              key={j}
                              audit_update
                              name={change.key_name}
                              to={change.value}
                            />
                            {audit.changes.length - 1 > j && ", "}
                          </>
                        ))}
                      {audit.comment && <>&nbsp;({audit.comment})</>}
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
              ))}
            </Timeline>
          )}
        </CardContent>
      </Card>
    </Stack>
  );
}

export default function (props) {
  const { open, onClose } = props;
  return (
    <RightDrawerLayout
      open={open}
      onClose={onClose}
      title="Change Hisory"
      icon={BuildingOffice2Icon}
    >
      <ShowDrawerContent {...props} onClose={onClose} />
    </RightDrawerLayout>
  );
}
