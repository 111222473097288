import React from "react";
import Avatar from "@mui/material/Avatar";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import SvgIcon from "@mui/material/SvgIcon";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import useMediaQuery from "@mui/material/useMediaQuery";
import { alpha } from "@mui/material/styles";
import XMarkIcon from "@heroicons/react/24/solid/XMarkIcon";
import i18n from "../../utils/i18n";

const TOP_NAV_HEIGHT = 64;

export const RightDrawerLayout = ({
  open,
  onClose,
  title,
  icon,
  iconColor,
  avatar,
  avatarVariant,
  hideBackdrop,
  children,
}) => {
  const mdUp = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const Icon = icon;

  return (
    <Drawer
      hideBackdrop={hideBackdrop}
      anchor="right"
      open={open}
      onClose={(e, reason) => reason == "escapeKeyDown" && onClose()}
      PaperProps={{ sx: { width: mdUp ? "65%" : "100%" } }}
      sx={{ zIndex: 1200 }}
    >
      <Box
        component="header"
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 9999,
          backdropFilter: "blur(6px)",
          backgroundColor: (theme) =>
            alpha(theme.palette.background.default, 0.8),
        }}
      >
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          spacing={2}
          sx={{
            minHeight: TOP_NAV_HEIGHT,
            p: 4,
          }}
        >
          <Typography
            variant="h2"
            sx={{ display: "flex", alignItems: "center" }}
          >
            {icon && (
              <SvgIcon
                color={iconColor || "disabled"}
                fontSize="large"
                sx={{ mr: 2 }}
                viewBox="0 -4 24 20"
              >
                <Icon />
              </SvgIcon>
            )}
            {avatar && (
              <Avatar
                variant={avatarVariant || "rounded"}
                src={avatar}
                sx={{ width: 32, height: 32, mr: 2 }}
              />
            )}
            {title}
          </Typography>
          <Tooltip title={i18n.t("close")}>
            <IconButton onClick={onClose}>
              <SvgIcon>
                <XMarkIcon />
              </SvgIcon>
            </IconButton>
          </Tooltip>
        </Stack>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 4,
        }}
      >
        {open && children}
      </Box>
    </Drawer>
  );
};
