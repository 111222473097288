import React, { useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Popover from "@mui/material/Popover";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Badge from "@mui/material/Badge";
import ArrowRightOnRectangleIcon from "@heroicons/react/24/solid/ArrowRightOnRectangleIcon";
import LifebuoyIcon from "@heroicons/react/24/solid/LifebuoyIcon";
import UserCircleIcon from "@heroicons/react/24/solid/UserCircleIcon";
import Confirm from "../common/Confirm";
import routes from "../../utils/routes";
import I18n from "../common/I18n";
import i18n from "../../utils/i18n";

export const AccountPopover = ({
  anchorEl,
  onClose,
  open,
  account,
  current_user,
}) => {
  const [openSupportUser, setOpenSupportUser] = useState(false);
  const onSupportUserClick = () => setOpenSupportUser(true);
  const onSupportUserClose = () => setOpenSupportUser(false);

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: "left",
        vertical: "bottom",
      }}
      onClose={onClose}
      open={open}
      PaperProps={{ sx: { width: 240 } }}
    >
      <Box sx={{ py: 1.5, px: 2 }}>
        <Typography variant="overline" sx={{ lineHeight: 1.5 }}>
          {account.name}
        </Typography>
        <Stack direction="row" justifyContent="space-between">
          <Typography color="text.secondary" variant="body2">
            {current_user.name}
          </Typography>
          <Chip label={current_user.role} size="small" />
        </Stack>
      </Box>
      <Divider />
      <MenuList
        disablePadding
        dense
        sx={{
          p: "8px",
          "& > *": {
            borderRadius: 1,
          },
        }}
      >
        <MenuItem href={routes.user()} onClick={onClose} component="a">
          <ListItemIcon>
            <SvgIcon fontSize="small">
              <UserCircleIcon />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText>
            <I18n user_profile />
          </ListItemText>
          {!current_user.email_verified && (
            <Badge color="warning" variant="dot" />
          )}
        </MenuItem>
        <MenuItem
          href={routes.users_sign_out()}
          component="a"
          data-turbo="false"
        >
          <ListItemIcon>
            <SvgIcon fontSize="small">
              <ArrowRightOnRectangleIcon />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText>
            <I18n sign_out />
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={onSupportUserClick} component="a">
          <ListItemIcon>
            <SvgIcon fontSize="small">
              <LifebuoyIcon />
            </SvgIcon>
            {account.customer_support_invited && (
              <Badge color="error" variant="dot" />
            )}
          </ListItemIcon>
          <ListItemText>
            <I18n invite_support_user />
          </ListItemText>
        </MenuItem>
      </MenuList>
      <Confirm
        open={openSupportUser}
        onClose={onSupportUserClose}
        title={i18n.t("invite_support_user")}
        actions={[
          {
            onClick: onSupportUserClose,
            label: i18n.t("cancel"),
          },
          {
            href: routes.invite_support_user_user(),
            variant: "contained",
            color: "primary",
            label: i18n.t("invite"),
          },
        ]}
      >
        <I18n invite_support_user_description />
      </Confirm>
    </Popover>
  );
};
