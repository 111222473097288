import React, { useState, useRef } from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import InputLabel from "@mui/material/InputLabel";
import Switch from "@mui/material/Switch";
import SvgIcon from "@mui/material/SvgIcon";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import PencilIcon from "@heroicons/react/24/solid/PencilIcon";
import DocumentTextIcon from "@heroicons/react/24/outline/DocumentTextIcon";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { RightDrawerLayout } from "../../layouts/RightDrawerLayout";
import { Form } from "../../common/Form";
import SubmitButton from "../../common/SubmitButton";
import I18n from "../../common/I18n";
import i18n from "../../../utils/i18n";
import routes from "../../../utils/routes";
import Confirm from "../../common/Confirm";
import BankCard from "./BankCard";
import ParamSchema from "./ParamSchema";
import currencyName from "../../../utils/currency-name";
import api from "../../../utils/api";

function EditDrawerContent({ bank_account, onUpdateBankAccount }) {
  const formRef = useRef();
  const [openDelete, setOpenDelete] = useState(false);
  const [openSwitch, setOpenSwitch] = useState(false);
  const [active, setActive] = useState(bank_account.status == "active");
  const [forceLoading, setForceLoading] = useState(false);
  const [bankAccountProof, setBankAccountProof] = useState(
    !!bank_account.pdf_confirmation,
  );
  const toggleActive = () => {
    if (active) openSwitchConfirm();
    else setActive(!active);
  };
  const openDeleteConfirm = () => setOpenDelete(true);
  const onCloseDeleteConfirm = () => setOpenDelete(false);
  const openSwitchConfirm = () => setOpenSwitch(true);
  const onCloseSwitchConfirm = () => setOpenSwitch(false);
  const onCloseSwitchConfirmWithSetActiveFalse = () => {
    setOpenSwitch(false);
    setActive(false);
  };
  const SCHEMA = ParamSchema(bank_account.currency);
  const bank_account_info =
    (bank_account.profile?.bank_account_info &&
      bank_account.profile.bank_account_info[0]) ||
    {};
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      min_balance: bank_account.profile.min_balance || 1000,
      min_transfer: bank_account.profile.min_transfer || 10000,
      threshold_balance: bank_account.profile.threshold_balance || 500,
      n_days_holding: bank_account.profile.n_days_holding || 3,
      bank_name: bank_account_info.bank_name,
      bank_swift_code: bank_account_info.bank_swift_code,
      bank_account_number: bank_account_info.bank_account_number,
      account_name: bank_account_info.account_name,
    },
    resolver: yupResolver(SCHEMA),
  });
  const min_balance = watch("min_balance");
  const min_transfer = watch("min_transfer");
  const n_days_holding = watch("n_days_holding");
  const updateBankAccount = (event) => {
    event.preventDefault();
    setForceLoading(true);
    api({
      url: routes.account_integration({ id: bank_account.id }),
      method: "put",
      data: {
        authenticity_token: document.querySelector("meta[name='csrf-token']")
          ?.content,
        active: active ? "on" : "off",
        min_balance,
        min_transfer,
        threshold_balance: getValues("threshold_balance"),
        n_days_holding,
        bank_name: getValues("bank_name"),
        bank_swift_code: getValues("bank_swift_code"),
        bank_account_number: getValues("bank_account_number"),
        account_name: getValues("account_name"),
        bank_account_proof: formRef.current.bank_account_proof.files[0],
        format: "json",
      },
      onSuccess: (res) => onUpdateBankAccount(res),
    });
  };

  return (
    <>
      <Form
        ref={formRef}
        enctype="multipart/form-data"
        action={routes.account_integration({ id: bank_account.id })}
        onSubmit={(event) =>
          handleSubmit(
            () => onUpdateBankAccount && updateBankAccount(event),
            () => event.preventDefault(),
          )()
        }
        method="put"
      >
        <Stack spacing={2}>
          <BankCard
            bank={bank_account}
            action={
              <Switch checked={active} onChange={toggleActive} name="active" />
            }
          >
            <Typography variant="body2">
              <b>
                <I18n currency />
                :&nbsp;{currencyName(bank_account.currency)}
              </b>
            </Typography>
            {!bank_account.bank.profile.capabilities.includes(
              "get_balance",
            ) && (
              <Typography variant="subtitle2" sx={{ fontSize: "80%" }}>
                <I18n
                  chrome_extension_balance_update_subtitle
                  name={bank_account.short_name}
                />
                <br />
                <a
                  href={i18n.t("chrome_extension_url")}
                  target="_blank"
                  rel="noreferrer"
                >
                  <I18n chrome_extension_install />
                </a>
              </Typography>
            )}
          </BankCard>
          {!bank_account.bank.profile.capabilities.includes("get_balance") && (
            <Card>
              <CardHeader
                title={i18n.t("pseudo_bank_account_information")}
                subheader={i18n.t("pseudo_bank_account_information_subtitle")}
              />
              <Divider />
              <CardContent>
                <Stack spacing={2}>
                  <TextField
                    fullWidth
                    required
                    variant="standard"
                    label={i18n.t("bank_account_number") + ":"}
                    helperText={errors.bank_account_number?.message}
                    {...register("bank_account_number")}
                    error={"bank_account_number" in errors}
                  />
                  <TextField
                    fullWidth
                    required
                    variant="standard"
                    label={i18n.t("bank_account_name") + ":"}
                    helperText={errors.bank_account_name?.message}
                    {...register("account_name")}
                    error={"account_name" in errors}
                  />
                  <InputLabel
                    shrink
                    variant="standard"
                    htmlFor="raised-button-file"
                  >
                    <I18n bank_account_proof />
                  </InputLabel>
                  {bankAccountProof && (
                    <Chip
                      label={i18n.t("bank_account_proof")}
                      variant="outlined"
                      icon={
                        <SvgIcon>
                          <DocumentTextIcon />
                        </SvgIcon>
                      }
                      onClick={() =>
                        window.open(
                          routes.confirmation_letter({
                            id: bank_account.pdf_confirmation,
                            format: "pdf",
                          }),
                        )
                      }
                      onDelete={() => setBankAccountProof(false)}
                      sx={{ marginRight: "auto!important" }}
                    />
                  )}
                  {!bankAccountProof && (
                    <input
                      id="raised-button-file"
                      type="file"
                      name="bank_account_proof"
                      accept="application/pdf"
                    />
                  )}
                </Stack>
              </CardContent>
            </Card>
          )}
          {active && (
            <Card>
              <CardHeader title={i18n.t("configure_operational_settings")} />
              <Divider />
              <CardContent>
                <Stack spacing={2}>
                  <TextField
                    fullWidth
                    required
                    variant="standard"
                    type="number"
                    inputProps={{ step: 0.01 }}
                    label={
                      i18n.t("minimum_daily_balance_to_be_maintained") + ":"
                    }
                    helperText={errors.min_balance?.message}
                    {...register("min_balance")}
                    error={"min_balance" in errors}
                    autoFocus
                  />
                  <TextField
                    fullWidth
                    required
                    variant="standard"
                    type="number"
                    inputProps={{ step: 0.01 }}
                    label={
                      i18n.t("minimum_transfer_amount_between_accounts") + ":"
                    }
                    helperText={errors.min_transfer?.message}
                    {...register("min_transfer")}
                    error={"min_transfer" in errors}
                  />
                  <TextField
                    fullWidth
                    required
                    variant="standard"
                    type="number"
                    inputProps={{ step: 0.01 }}
                    label={i18n.t("threshold_alert_if_balance_below") + ":"}
                    helperText={errors.threshold_balance?.message}
                    {...register("threshold_balance")}
                    error={"threshold_balance" in errors}
                  />
                  <TextField
                    fullWidth
                    required
                    variant="standard"
                    type="number"
                    inputProps={{ step: 1 }}
                    label={i18n.t("n_days_holding_funds") + ":"}
                    helperText={errors.n_days_holding?.message}
                    {...register("n_days_holding")}
                    error={"n_days_holding" in errors}
                  />
                  <Typography variant="subtitle2">
                    <I18n
                      automation_threshold_subtitle_1
                      amount={i18n.numberToRounded(parseFloat(min_balance), {
                        delimiter: ",",
                        precision: 2,
                      })}
                    />
                    <ol>
                      <li>
                        {(bank_account.profile.capabilities.includes(
                          "create_payment",
                        ) && (
                          <I18n
                            automation_threshold_subtitle_2
                            amount={i18n.numberToRounded(
                              parseFloat(min_balance) +
                                parseFloat(min_transfer),
                              { delimiter: ",", precision: 2 },
                            )}
                          />
                        )) || (
                          <I18n
                            automation_threshold_subtitle_2a
                            amount={i18n.numberToRounded(
                              parseFloat(min_balance) +
                                parseFloat(min_transfer),
                              { delimiter: ",", precision: 2 },
                            )}
                          />
                        )}
                      </li>
                      <li>
                        {(parseFloat(min_balance) >
                          parseFloat(min_transfer) && (
                          <I18n
                            automation_threshold_subtitle_3a
                            amount={i18n.numberToRounded(
                              parseFloat(min_balance) -
                                parseFloat(min_transfer),
                              { delimiter: ",", precision: 2 },
                            )}
                          />
                        )) || (
                          <I18n
                            automation_threshold_subtitle_3b
                            amount={i18n.numberToRounded(
                              parseFloat(min_transfer),
                              { delimiter: ",", precision: 2 },
                            )}
                          />
                        )}
                      </li>
                    </ol>
                    <I18n automation_threshold_subtitle_4 />
                    <br />
                    <I18n n_days_holding_subtitle days={n_days_holding} />
                  </Typography>
                </Stack>
              </CardContent>
            </Card>
          )}
          {bank_account.bank_type == "pseudo_transactional" && (
            <Card>
              <CardHeader
                title={i18n.t("pseudo_bank_account_information")}
                subheader={i18n.t("pseudo_bank_account_information_subtitle")}
              />
              <Divider />
              <CardContent>
                <Stack spacing={2}>
                  <TextField
                    fullWidth
                    required
                    variant="standard"
                    label={i18n.t("bank_name") + ":"}
                    helperText={errors.bank_name?.message}
                    {...register("bank_name")}
                    error={"bank_name" in errors}
                  />
                  <TextField
                    fullWidth
                    required
                    variant="standard"
                    label={i18n.t("bank_swift_code") + ":"}
                    helperText={errors.bank_swift_code?.message}
                    {...register("bank_swift_code")}
                    error={"bank_swift_code" in errors}
                  />
                  <TextField
                    fullWidth
                    required
                    variant="standard"
                    label={i18n.t("bank_account_number") + ":"}
                    helperText={errors.bank_account_number?.message}
                    {...register("bank_account_number")}
                    error={"bank_account_number" in errors}
                  />
                  <TextField
                    fullWidth
                    required
                    variant="standard"
                    label={i18n.t("bank_account_name") + ":"}
                    helperText={errors.bank_account_name?.message}
                    {...register("account_name")}
                    error={"account_name" in errors}
                  />
                </Stack>
              </CardContent>
            </Card>
          )}
        </Stack>
        <Box sx={{ mt: 2, display: "flex", flexDirection: "column" }}>
          <SubmitButton
            fullWidth
            variant="contained"
            type="submit"
            forceLoading={forceLoading}
          >
            <I18n update />
          </SubmitButton>
          <Button
            color="error"
            onClick={openDeleteConfirm}
            sx={{ mt: 1, alignSelf: "flex-end" }}
          >
            <I18n delete />
          </Button>
        </Box>
        <Confirm
          open={openDelete}
          onClose={onCloseDeleteConfirm}
          title={i18n.t("delete_bank_account_integration")}
          actions={[
            {
              onClick: onCloseDeleteConfirm,
              label: i18n.t("cancel"),
            },
            {
              href: routes.account_integration({ id: bank_account.id }),
              color: "error",
              variant: "contained",
              label: i18n.t("delete"),
              method: "delete",
            },
          ]}
        >
          <I18n are_you_sure_to_delete_model model={bank_account.name} />
          &nbsp;
          <I18n this_action_cannot_be_undone />
        </Confirm>
        <Confirm
          open={openSwitch}
          onClose={onCloseSwitchConfirm}
          title={i18n.t("bank_account_integration_switch_to_off")}
          actions={[
            {
              onClick: onCloseSwitchConfirm,
              label: i18n.t("cancel"),
            },
            {
              onClick: onCloseSwitchConfirmWithSetActiveFalse,
              label: i18n.t("confirm"),
              variant: "contained",
              primary: true,
            },
          ]}
        >
          <I18n are_you_sure />
        </Confirm>
      </Form>
    </>
  );
}

export default function (props) {
  const { open, onClose } = props;
  return (
    <RightDrawerLayout
      open={open}
      onClose={onClose}
      title={i18n.t("integrations")}
      icon={PencilIcon}
    >
      <EditDrawerContent {...props} />
    </RightDrawerLayout>
  );
}
